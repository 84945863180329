import React from 'react';

class AboutTab extends React.Component {

  render() {
    if (this.props.lang === 'jp') {
      return (
        <div className='about-tab'>
          <p>初めまして。ラクーナ・ダンです。</p>
          <p>カナダのトロント大学でコンピューターサイエンスを学びました。</p>
          <p>大学でソフトウェア開発を専攻し、卒業後はウェブプログラマーとして働きました。</p>
          <p>それ以来、ウェブプログラミングに魅了され続けています。</p>
          <p>2014年、新しい道を切り開くために日本に来ました。</p>
          <p>モバイルアプリ開発やゲーム開発、グラフィックデザインの経験もあります。</p> 
          <p>趣味はギターで、最近フィンガーピッキングで弾くことにハマっています。</p>
          <p>兵庫県伊丹市を拠点としています。</p>
        </div>
      )
    } else {
      return (
        <div className='about-tab'>
          <p>Greetings! I am Patrick Dan. You may call me Patrick or Dan.</p>
          <p>
            I'm a University of Toronto alumnus, majoring in Computer Science, specializing in Software Engineering.
            Since graduating, I have been seduced by the thrilling industry of web development.
            I have worked in the industry as a full stack web developer before moving to Japan to explore further opportunities.
          </p>
          <p>
            Along the way, I have also delved into mobile and game development, as well as web and graphic design.
            I'm currently working on personal game projects using the Unity game engine.
          </p>
          <p>
            I'm also pretty badass with the guitar. Fingerstyle guitar.
          </p>
          <p>
            Currently based near Osaka, Japan.
          </p>
        </div>
      );
    }
  }
}
export default AboutTab;