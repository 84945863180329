import React from 'react';

const PortfolioSection = ({ portfolio }) => (
  <div className='portfolio-section row justify-content-center'>
    <span className='portfolio-section-title'>{portfolio.title}</span>
    <div className='portfolio-section-preview'>
      <img
        src={portfolio.previewSrc}
        alt='preview'
      />
    </div>
    <div className='portfolio-section-preview-overlay'>
      <a href={portfolio.github}>  
        <i className='fab fa-github-square' />
      </a>
      {
        portfolio.link ? 
        (
          <a href={portfolio.link}>  
            <i className="fas fa-file-alt" />
          </a>
        )
        : ''
      }
    </div>
    <span className='portfolio-section-description'>
      {portfolio.description}
    </span>
  </div>
);

export default PortfolioSection;