import React from 'react';
import PortfolioSection from './PortfolioSection';

class PortfolioTab extends React.Component {
  
  render() {
    const { portfolioList } = this.props;
    if (this.props.lang === 'jp') {
      return (
        <div className='portfolio-tab container'>
          開発中
        </div>
      );
    } else {
      return (
        <div className='portfolio-tab container'>
          {
            portfolioList.map( (portfolio) => (
              <PortfolioSection key={portfolio.id} portfolio={portfolio} />
            ))
          }
        </div>
      );
    }
  }  
}

export default PortfolioTab;