import React, { Component } from 'react';
import AboutTab from './AboutTab';
import PortfolioTab from './PortfolioTab';
import PortfolioList from '../PortfolioList';

class PtrkLacuna extends Component {
  state = {
    activeTab: 'About',
    lang: 'en'
  }

  handleTabChange = (tab) => {
    this.setState({
      activeTab: tab
    });
  }

  handleLangChange = (lang) => {
    this.setState({
      lang
    });
  }

  render() {
    return (
      <div className='main container'>
        <div className='title row justify-content-center'>
          <div className='col-12'>
            <span>Patrick Dan Lacuna</span>
          </div>
        </div>
        <div className='subtitle row justify-content-center'>
          <div className='col-12'>
            <span>Web|Game Dev</span>
          </div>
        </div>
        <hr />
        <div className='tabs row justify-content-center'>
          <div 
            className={'tab link col-2' + (this.state.activeTab === 'About' ? ' active' : '') } 
            onClick={()=>this.handleTabChange('About')}>
            <span>About</span>
          </div>
          <div 
            className={'tab link col-2' + (this.state.activeTab === 'Portfolio' ? ' active' : '') }
            onClick={()=>this.handleTabChange('Portfolio')}>
            <span>Portfolio</span>
          </div>
        </div>
        <div className='content row justify-content-center no-gutters'>
          <div className='col-10'>
          { this.state.activeTab === 'About' ?
            <AboutTab lang={this.state.lang} />
            :
            <PortfolioTab lang={this.state.lang} portfolioList={PortfolioList} />
          }
          </div>
        </div>
        <hr />
        <div className='contact-info row justify-content-center no-gutters'>
          <span className='contact-icon link col-1'>
            <a href='mailto:contactme@ptrklacuna.com'>
              <i className='far fa-envelope' />
            </a>
          </span>
          <span>|</span>
          <span className='contact-icon link col-1'>
            <a href='https://github.com/ptrkdan'>
              <i className='fab fa-github-square' />
            </a>
          </span>
          <span>|</span>
          <span className='contact-icon link col-1'>
            <a href='http://ca.linkedin.com/in/ptrklacuna/'>
              <i className='fab fa-linkedin' />
            </a>
          </span>
        </div>
        <div className='lang-select row justify-content-center'>
          <span 
            className={'lang en link' + (this.state.lang === 'en' ? ' active' : null)}
            onClick={() => this.handleLangChange('en')}>EN</span>
          <span style={{ marginLeft: 5, marginRight: 5 }} >|</span>
          <span 
            className={'lang en link' + (this.state.lang === 'jp' ? ' active' : null)}
            onClick={() => this.handleLangChange('jp')}>JP</span>
        </div>
      </div>
    )
  }
}

export default PtrkLacuna;