const PortfolioList = [
  {
    id: 0,
    title: 'Udacity Nanodegree Project: Readable',
    previewSrc: '/images/readable-preview.png',
    github: 'https://github.com/ptrkdan/readables/',
    description: 'Udacity Nanodegree Project using React Redux'
  },
  {
    id: 1,
    title: 'Project Ludicrous Speed',
    previewSrc: '/images/pls-preview.jpg',
    github: 'https://github.com/ptrkdan/Project-Ludicrous-Speed',
    link: 'https://drive.google.com/file/d/1-rn1zXaK8DgksvT3V9WhwwgcPYmdpHuh/view?usp=sharing',
    description: 'A sci-fi side-scrolling looter shooter game prototype on Unity'
  },
  {
    id: 2,
    title: 'Project Space Brigade',
    previewSrc: '/images/psb-preview.jpg',
    github: 'https://github.com/ptrkdan/space-brigade',
    link: 'https://drive.google.com/file/d/1ZaT7R_sq16lFX7T_9L8hhIJmlMtmbxKO/view?usp=sharing',
    description: 'Plants vs. Zombie-style strategy game prototype on Unity'
  }
]

export default PortfolioList;
